/* src/components/Footer.css */
.footer {
    background-color: #d73259;
    color: #fff;
    padding: 20px 0;
    text-align: center;
  }
  
  .footer-content {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .footer-section {
    flex: 1;
    margin: 10px;
    min-width: 200px;
  }
  
  .footer-section h4 {
    border-bottom: 2px solid #fff;
    padding-bottom: 10px;
    margin-bottom: 10px;
  }
  
  .footer-section p {
    margin: 5px 0;
  }
  
  .social-icons {
    display: flex;
    justify-content: space-around;
  }
  
  .social-icons a {
    color: #fff;
    margin: 0 10px;
    font-size: 24px;
    text-decoration: none;
  }
  
  .footer-bottom {
    margin-top: 20px;
  }
  