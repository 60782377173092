/* src/CaseConverter.css */
body {
    background-color: #f8f9fa; /* Light background color for the whole page */
}

.card {
    border-radius: 15px;
    background: linear-gradient(135deg, #4e54c8, #8f94fb); /* Gradient background for the card */
}

.card-title {
    font-size: 2.2rem;
    font-weight: bold;
}

.text-output {
    font-size: 1.3rem;
    padding: 15px;
    border-radius: 5px;
    border: 1px solid #ced4da;
    background-color: #ffffff;
    text-align: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s ease, transform 0.3s ease;
}

.text-output:hover {
    background-color: #e9ecef;
    transform: scale(1.02);
}

button {
    transition: background-color 0.3s ease, transform 0.3s ease;
}

button:hover {
    background-color: #0056b3;
    transform: scale(1.05);
}

button:active {
    background-color: #003d80;
}
