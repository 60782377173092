/* Background color for the mortgage calculator container */
.mortgage-calculator-container {
    background-color: #f0f4f8; /* Light blue-grey background */
}

/* Style for the calculator card */
.card {
    background-color: #ffffff; /* White background for card */
}

/* Style for the Calculate button */
.btn-calculate {
    background-color: #b42f41; /* Bright blue */
    border: none;
    color: white;
    transition: background-color 0.3s ease;
}

.btn-calculate:hover {
    background-color: #f11c55; /* Darker blue on hover */
}

/* Style for the Download CSV button */
.btn-download {
    background-color: #28a745; /* Green */
    border: none;
    color: white;
    transition: background-color 0.3s ease;
}

.btn-download:hover {
    background-color: #218838; /* Darker green on hover */
}

/* Styling for the result section */
.result {
    font-size: 1.25em;
    color: #333;
}

/* Style for the heading */
.heading {
    color: rgb(203, 53, 53); 
}

/* Tooltip style */
.tooltip-inner {
    background-color: #333;
    color: #fff;
}
