.fade-enter {
    opacity: 0;
    transform: scale(0.9);
}
.fade-enter-active {
    opacity: 1;
    transform: scale(1);
    transition: opacity 500ms, transform 500ms;
}
.fade-exit {
    opacity: 1;
    transform: scale(1);
}
.fade-exit-active {
    opacity: 0;
    transform: scale(0.9);
    transition: opacity 500ms, transform 500ms;
}

.color-box {
    width: 50px;
    height: 50px;
    border: 1px solid #ddd;
    margin-right: 10px;
    display: inline-block;
    margin-bottom: 10px;
}

.color-box-sm {
    width: 20px;
    height: 20px;
    border: 1px solid #ddd;
    margin-right: 5px;
    display: inline-block;
}

.color-list {
    display: flex;
    flex-wrap: wrap;
}

.color-picker {
    margin-bottom: 10px;
}

.toast-container {
    position: fixed;
    top: 20px;
    right: 20px;
    z-index: 1050;
}
