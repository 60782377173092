/* Calculator.css */

/* Bounce animation */
@keyframes bounce {
    0%, 20%, 50%, 80%, 100% {
      transform: translateY(0);
    }
    40% {
      transform: translateY(-30px);
    }
    60% {
      transform: translateY(-15px);
    }
  }
  
  /* Apply bounce animation to profit result */
  .bounce {
    animation: bounce 2s infinite;
  }
  
  /* General styling for the form */
  .calculator-container {
    margin-top: 50px;
    background-color: #f8f9fa; /* Light gray background for the calculator */
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .calculator-title {
    text-align: center;
    margin-bottom: 20px;
    background-color: #f40940; /* Bootstrap primary color */
    color: white;
    padding: 10px;
    border-radius: 5px;
  }
  
  .calculator-button {
    margin-right: 10px;
  }
  
  .output-container {
    background-color: #28a745; /* Bootstrap success color */
    color: white;
    padding: 10px;
    border-radius: 5px;
    margin-top: 20px; /* Margin from top */
  }
  
  .cost-breakdown {
    margin-top: 20px;
  }
  
  .cost-breakdown h5 {
    margin-bottom: 10px;
  }
  
  .tooltip-icon {
    margin-left: 5px;
    cursor: pointer;
    color: #6c757d; /* Bootstrap secondary color */
  }
  