.qr-code-generator {
  text-align: center;
  margin: 20px auto;
  padding: 20px;
  max-width: 600px;
  border: 1px solid #ddd;
  border-radius: 10px;
  background-color: #f9f9f9;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease;
}

.qr-code-generator:hover {
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.title {
  font-size: 2rem;
  margin-bottom: 20px;
  color: red !important;
}



.input {
  width: 80%;
  padding: 10px;
  font-size: 16px;
  margin-bottom: 20px;
  border: 1px solid #ddd;
  border-radius: 5px;
  transition: border-color 0.3s ease;
}

.input:focus {
  border-color: #007bff;
  outline: none;
}

.controls {
  margin-bottom: 20px;
}

.control-input {
  margin: 10px;
  padding: 5px;
  border-radius: 5px;
  border: 1px solid #ddd;
}

.download-button {
  padding: 10px 20px;
  font-size: 16px;
  color: #fff;
  background-color: #007bff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.download-button:hover {
  background-color: #0056b3;
  transform: scale(1.05);
}

.qr-code-container {
  margin-top: 20px;
  animation: fadeIn 1s ease-in-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

input[type="range"] {
  width: 100%;
  margin: 10px 0;
}
