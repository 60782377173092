/* src/WordCounter.css */
body {
    background-color: #f0f2f5; /* Light background color for the whole page */
}

.card {
    border-radius: 15px;
    background: linear-gradient(135deg, #00b4db, #0083b0); /* Gradient background for the card */
}

.card-title {
    font-size: 2.2rem;
    font-weight: bold;
}

textarea {
    transition: box-shadow 0.3s ease;
}

textarea:focus {
    box-shadow: 0 0 8px rgba(0, 123, 255, 0.5);
}

.card-body {
    transition: transform 0.3s ease, background-color 0.3s ease;
}

.card-body:hover {
    background-color: #343a40;
    transform: scale(1.02);
}

button {
    transition: background-color 0.3s ease, transform 0.3s ease;
}

button:hover {
    background-color: #0056b3;
    transform: scale(1.05);
}

button:active {
    background-color: #003d80;
}
