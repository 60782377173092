/* Basic styling for the To-Do List application */
.task-list {
    max-height: 400px;
    overflow-y: auto;
}

.task-item {
    transition: background-color 0.3s, transform 0.3s;
}

.task-item.completed {
    background-color: #d4edda;
    text-decoration: line-through;
}

.task-item:hover {
    background-color: #f1f1f1;
    transform: scale(1.02);
}

.modal-content {
    background-color: #f8f9fa;
}

.bg-success {
    background-color: #28a745 !important;
}

.bg-primary {
    background-color: #007bff !important;
}

input[type="date"] {
    border-radius: 0.25rem;
}

input[type="text"] {
    border-radius: 0.25rem;
}

select {
    border-radius: 0.25rem;
}

.button {
    margin: 0.5rem;
}
.list-group-item {
    color: #000000; /* Black color for the todo item text */
}