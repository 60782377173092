body {
    font-family: Arial, sans-serif;
    background-color: #f8f9fa;
  }
  .title-big-red {
    font-size: 2.5rem; /* Adjust the size as needed */
    color: #ff0000;    /* Red color */
    font-weight: bold; /* Optional: makes the text bold */
    text-align: center; /* Center-aligns the title */
  }
  .income-input {
    width: 100%;
  }
  
  .income-row {
    margin-bottom: 1rem;
  }
  
  .income-col {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
    
  
  
  .card {
    margin: 20px;
  }
  
  .button-add, .button-export {
    width: 100%;
    margin-top: 10px;
  }
  
  .button-edit {
    margin-right: 10px;
  }
  
  .table-row:hover {
    background-color: #f1f1f1;
  }
  
  .modalFadeIn {
    animation: modalFadeIn 0.5s;
  }
  
  @keyframes modalFadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  
  