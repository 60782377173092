.meme-generator {
    max-width: 800px;
    margin: 30px auto;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 10px;
    background: linear-gradient(135deg, #f9f9f9, #e2e2e2);
    text-align: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .title {
    font-size: 2.5rem;
    margin-bottom: 20px;
    color: #333;
  }
  
  .dropzone {
    border: 2px dashed #007bff;
    padding: 20px;
    margin-bottom: 20px;
    cursor: pointer;
    transition: border-color 0.3s ease;
  }
  
  .dropzone:hover {
    border-color: #0056b3;
  }
  
  .meme-preview {
    position: relative;
    display: inline-block;
    margin-bottom: 20px;
  }
  
  .text {
    position: absolute;
    width: 100%;
    text-align: center;
    font-weight: bold;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  }
  
  .top-text {
    top: 10px;
  }
  
  .bottom-text {
    bottom: 10px;
  }
  
  .controls {
    margin-top: 20px;
  }
  
  .controls input[type="text"],
  .controls input[type="number"],
  .controls input[type="color"] {
    margin: 10px;
    padding: 10px;
    border-radius: 8px;
    border: 1px solid #ddd;
  }
  
  .download-button {
    padding: 15px 30px;
    font-size: 18px;
    color: #fff;
    background-color: #007bff;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .download-button:hover {
    background-color: #0056b3;
  }
  