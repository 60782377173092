.p-4 {
  padding: 1.5rem;
  background-color: #f8f9fa; /* Light grey background */
  border-radius: 0.25rem;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
}

.card-title {
  color: #ff6347; /* Tomato */
  font-size: 1.5rem;
}

.ql-container {
  height: 300px;
}

.btn-success {
  background-color: #28a745;
  border-color: #28a745;
}

.btn-success:hover {
  background-color: #218838;
  border-color: #1e7e34;
  transition: background-color 1s ease;
}

.alert-success {
  background-color: #d4edda;
  border-color: #c3e6cb;
  color: #155724;
}

body {
  background-color: #f8f9fa; /* Light grey background for body */
}
