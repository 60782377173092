#navbar-bg{
    background-color: #d73259;
}
/* Home.css or your specific CSS file */

#logo {
    font-size: 2rem; /* Larger font size for better visibility */
    font-weight: bold; /* Make the text bold */
    color: #333; /* Dark color for better contrast */
    text-transform: uppercase; /* Uppercase letters for a strong look */
    letter-spacing: 1px; /* Slightly increase space between letters */
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2); /* Add shadow for depth */
    font-family: 'Arial', sans-serif; /* Choose a modern font */
    transition: color 0.3s ease, transform 0.3s ease; /* Smooth transition effects */
}

#logo:hover {
    color: #007bff; /* Change color on hover */
    transform: scale(1.1); /* Slightly enlarge the logo on hover */
}

.nav-link, .navbar-brand {
    color: #ffffff !important; /* White color for links and dropdowns */
}
.nav-link:hover {
    color: grey !important; /* Optional: Light grey color on hover */
    transition-duration: 0.5s;
    transition-timing-function: ease-in-out;
    transition-property: all;
}
/* #logo-span{
    color: #f7ef01;
} */


