.file-compressor {
    max-width: 800px; /* Keep the width as before for consistency */
    margin: 30px auto; /* Increase margin for better spacing */
    padding: 40px; /* Increase padding for more space inside the container */
    border: 1px solid #ddd;
    border-radius: 15px; /* More rounded corners */
    background: linear-gradient(135deg, #f9f9f9, #e2e2e2); /* Gradient background */
    text-align: center;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); /* More pronounced shadow */
    position: relative;
    animation: fadeIn 1s ease-in-out;
    min-height: 500px; /* Increase height */
  }
  
  .title {
    font-size: 2.5rem; /* Keep font size larger */
    margin-bottom: 20px;
    color: #333;
    transition: color 0.3s ease, transform 0.3s ease;
  }
  
  .title:hover {
    color: #007bff;
    transform: scale(1.05);
  }
  
  input[type="file"] {
    margin: 20px 0;
    padding: 10px;
    border-radius: 8px;
    border: 1px solid #ddd;
    background: #fff;
    box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
    transition: border-color 0.3s ease;
  }
  
  input[type="file"]:hover {
    border-color: #007bff;
  }
  
  .compress-button {
    padding: 20px 50px; /* Larger button */
    font-size: 20px; /* Larger font size */
    color: #fff;
    background-color: #007bff;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .compress-button:hover {
    background-color: #0056b3;
    transform: scale(1.05);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  
  .progress-container {
    margin-top: 20px;
    position: relative;
    width: 100%;
    background: #e9ecef;
    border-radius: 8px;
    height: 25px; /* Increased height for better visibility */
    overflow: hidden;
  }
  
  .progress-bar {
    height: 100%;
    background-color: #28a745;
    transition: width 0.3s ease;
  }
  
  .progress-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 18px;
    color: #333;
  }
  
  .file-info {
    margin-top: 20px;
  }
  
  .error-message {
    color: red;
    font-size: 16px;
    margin: 10px 0;
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  